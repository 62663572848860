<template>
  <div>
    <tr>
      <td>
        <b-form-input type="text" v-model="results.executionRank" v-show="editionMode" />
        <span v-show="!editionMode">{{ results.executionRank}}</span>
      </td>
      <td>
        <b-form-input type="text" v-model="competitor.name" v-show="editionMode" />
        <span v-show="!editionMode">{{ results.name}}</span>
      </td>
      <compulsory
        v-for="compulsory in compulsories"
        :key="compulsory.index"
        v-model="compulsory.value"
      />
    </tr>
  </div>
</template>

<script>
//import ResultsHelper from '@/services/helpers/resultsHelper';
import { compulsory } from '@/components/PostCompetitionResultEdition/Compulsory.vue';

export default {
  name: 'Row',
  components: { compulsory },
  props: {
    editionMode: {type: Boolean, default: true },
  },
}
</script>
