<template>
  <div>
    <td>
        <b-form-input type="text" v-model="value" v-show="editionMode" @change="value_change" />
        <span v-show="!editionMode">{{ value }}</span>
    </td>
  </div>
</template>

<script>
//import ResultsHelper from '@/services/helpers/resultsHelper';

export default {
  name: "Compulsory",
  props: {
    competition_id: { type:String, require:true },
    round_id: { type: String, require:true},
    competitor_id: { type: String, require:true},
    compulsory_index: { type: Number, require: true},
    judge_id: { type: String, require: false, default: null},
    value: { type:Number, default:null},
    editionMode:{ type:Boolean, default:true }
  },
  methods: {
    value_change(newValue){
      var arg = { index: this.compulsory_index, value: newValue};
      this.$emit("change", arg);
    }
  }
}
</script>
