<template>
<div>
  <div class="form">
    <fieldset>
      <b-form-input v-model="competition.id"></b-form-input>
      <b-form-group label="Nom" class="required">
        <b-form-input v-model="competition.name" @change="name_change"></b-form-input>
      </b-form-group>
      <b-form-group label="Année" class="required">
        <b-form-input v-model="competition.year" @change="year_change"></b-form-input>
      </b-form-group>
      <b-form-group label="Type de compétition" class="required">
        <b-form-input v-model="competition.competition_type.name"></b-form-input>
      </b-form-group>
      <b-form-group label="Echelon" class="readonly">
        <b-form-input v-model="competition.scope.name"></b-form-input>
      </b-form-group>
      <b-form-group label="Emplacement" class="required">
        <b-form-input v-model="competition.location"></b-form-input>
      </b-form-group>
      <b-form-group label="Nb. Rounds" class="readonly">
        <b-form-input v-model="competition.nbRounds"></b-form-input>
      </b-form-group>
      <b-form-group label="Du" class="readonly">
        <b-form-input v-model="competition.fromDate"></b-form-input>
      </b-form-group>
      <b-form-group label="Au" class="readonly">
        <b-form-input v-model="competition.toDate"></b-form-input>
      </b-form-group>
    </fieldset>
    <b-list-group>
      <b-list-group-item v-for="staffMember in competition.staff" :key="staffMember.id">
        {{ staffMember.firstname }} {{ staffMember.lastname }}
      </b-list-group-item>
    </b-list-group>
    <ul>
      <li v-for="level in competition.levels" :key="level.code">
        {{ level.name }}
        <ul>
          <li v-for="category in level.categories" :key="category.code">
            {{ category.name }}
            <ul>
              <li v-for="competitor in competitors(level.code, category.code)" :key='competitor.id'>
                {{ competitor.name }}
                <ul v-if="!competitor.isIndividual">
                  <li v-for="pilot in competitor.pilots" :key="pilot.id">
                    {{ pilot.firstname}} {{ pilot.lastname }}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <hr />
    <ul>
      <li v-for="round in competition.rounds" :key="round.id">
        Manche {{ round.round_number}} ({{ round.date }})
        <ul>
          <li v-for="level in competition.levels" :key="level.code">
            <span v-if="competition.levels.length > 1">{{ level.name }}</span>
            <ul>
              <li v-for="category in level.categories" :key="category.code">
                <span v-if="level.categories.length > 1">{{ category.name }}</span>
                <table>
                  <thead>
                    <tr>
                      <td colspan="2" rowspan="3">Compétiteur</td>
                      <td colspan="5">Précision</td>
                      <td colspan="2" rowspan="2">Ballet</td>
                    </tr>
                    <tr>
                      <td colspan="3">Figures</td>
                      <td colspan="2">Routine</td>
                    </tr>
                    <tr>
                      <td>Fig. 1</td>
                      <td>Fig. 2</td>
                      <td>Fig. 3</td>
                      <td>Exec.</td>
                      <td>Cont.</td>
                      <td>Choreo.</td>
                      <td>Exec.</td>
                    </tr>
                  </thead>
                  <tbody>
                    <row
                      v-for="competitor in competitorsResuultsForRoundCategory(category.id, round.round_number)"
                      :key="competitor.competitor_id"
                      :results = "competitor"
                    />
                  </tbody>
                </table>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
import ResultsHelper from '@/services/helpers/resultsHelper';
import Row from '@/components/PostCompetitionResultEdition/Row.vue';

export default {
    components: { Row },
    data(){
      return {
        competition: { id: null, },
      };
    },
    methods: {
      async loadAndRefresh(){
          this.competition = await ResultsHelper.getCompetitionResults(this.competition.id);
      },
      competitors(level, category){
        return this.competition.competitors.filter(c => c.level == level && c.category == category);
      },
      competitorsResuultsForRoundCategory(category, round_number)
      {
        var round = this.competition.rounds.find(r => r.round_number == round_number);
        var notes = round.notes.filter(n => n.category == category);


        return notes;
      },
      name_change(newName){
        ResultsHelper.setNewName(newName);
      }
    },
    async mounted(){
      this.$hideLoader();
      this.competition.id = this.$route.params["id"];
      this.loadAndRefresh();
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                console.log("ListCompetitionsResults.vue > route changed : ", to);
                this.competition.id = to.params["id"];
                this.loadAndRefresh();
            }
        },
    },
}
</script>

<style scoped>
  .form { flex-wrap: wrap; justify-content: stretch; align-content: center;}
</style>
